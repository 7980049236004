import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { WalletPortfolios } from '@features/wallet/wallet-portfolios';
import { ContainerColumn } from '@src/components/styled';
import { checkIfWalletSupported } from '@entities/wallet';
import UnavailableList from './UnavailableList';
const ClientPortfoliosContainer = styled(ContainerColumn)(props => ({
    gap: props.theme.spacing_sizes.l,
}));
const ClientPortfoliosList = ({ walletsList, portfoliosList, supportedWalletsDict, }) => {
    const { availableWallets, unavailableWallets, } = useMemo(() => walletsList.reduce((acc, wallet) => {
        const isSupported = checkIfWalletSupported(wallet, supportedWalletsDict);
        if (isSupported) {
            acc.availableWallets.push(wallet);
        }
        else {
            acc.unavailableWallets.push(wallet);
        }
        return acc;
    }, {
        availableWallets: [],
        unavailableWallets: [],
    }), [walletsList, supportedWalletsDict]);
    return (_jsxs(ClientPortfoliosContainer, { children: [availableWallets.map((wallet) => {
                let portfolios = [];
                if (portfoliosList.length) {
                    portfolios = portfoliosList.filter((item) => item.walletId === wallet.id);
                }
                return (_jsx(WalletPortfolios, { wallet: wallet, portfoliosList: portfolios }, wallet.id));
            }), (unavailableWallets === null || unavailableWallets === void 0 ? void 0 : unavailableWallets.length) > 0 && (_jsx(UnavailableList, { children: unavailableWallets.map((wallet) => (_jsx(WalletPortfolios, { wallet: wallet, portfoliosList: [], hasError: true }, wallet.id))) }))] }));
};
export default ClientPortfoliosList;
